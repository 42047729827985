<template>
  <b-container class="quickScore">
    <div>
      <ReportTable
        :data="data"
        :title="title"
        :home="home"
        :updown="updown"
        :config="config"
        :team="teamEvent"
        :match="match"
        :currentTitle="currentTitle"
        :showGender="showGender"
        :is_qualifier="is_qualifier"
        :stableford="stableford"
      />
      <b-container style="display: contents;">
        <b-row style="margin-left:0; margin-right:0;">
          <b-col class="links red">
            <b-nav-item
              v-if="title === 'Tournament Entries'"
              class="Flisting-btn right"
              :to="{
                name: 'reports',
                query: {
                  url:
                    config.VUE_APP_PLAYER_HOST +
                    '/tic/tmentry.cgi?tourn=' +
                    courseCode +
                    '~season=' +
                    Year +
                    '~alphaorder~',
                  id: Year,
                  code: courseCode,
                  title: currentTitle,
                },
              }"
            >
              Full Listing
            </b-nav-item>
            <b-nav-item
              v-if="title === 'Draw'"
              class="left drawListing-btn"
              :to="{
                name: 'reports',
                query: {
                  url: reportURL,
                  id: Year,
                  code: courseCode,
                  title: currentTitle,
                },
              }"
            >
              Full Listing
            </b-nav-item>
            <b-nav-item
              v-if="title === 'Scores'"
              class="center three Flisting-btnt"
              :to="{
                name: 'reports',
                query: {
                  url: reportURL,
                  id: Year,
                  code: courseCode,
                  title: currentTitle,
                },
              }"
            >
              Leaderboard
            </b-nav-item>
            <b-nav-item
              v-if="title === 'Live'"
              class="center four Flisting-btn"
              :to="{
                name: 'reports',
                query: {
                  url: reportURL,
                  id: Year,
                  code: courseCode,
                  title: currentTitle,
                },
              }"
            >
              Leaderboard
            </b-nav-item>
            <b-nav-item
              v-if="title === 'Final Result'"
              class="center four Flisting-btn"
              :to="{
                name: 'reports',
                query: {
                  url: reportURL,
                  id: Year,
                  code: courseCode,
                  title: currentTitle,
                },
              }"
            >
              Full Listing
            </b-nav-item>
          </b-col>
          <!-- <b-col class="links red" v-if="title === 'Draw'">
            <b-nav-item
              v-if="title === 'Draw'"
              class="right drawListing-btn "
              :to="{
                name: 'reports',
                query: {
                  url:
                    config.VUE_APP_PLAYER_HOST +
                    '/tic/tmentry.cgi?tourn=' +
                    courseCode +
                    '~season=' +
                    Year +
                    '~alphaorder~',
                  id: Year,
                  code: courseCode,
                  title: 'Tournament Entries',
                },
              }"
            >
              Full Listing
            </b-nav-item>
            <b-nav-item
              v-if="title === 'Scores'"
              class="right Flisting-btnt three"
              :to="{
                name: 'reports',
                query: {
                  url:
                    config.VUE_APP_PLAYER_HOST +
                    '/tic/tmentry.cgi?tourn=' +
                    courseCode +
                    '~season=' +
                    Year +
                    '~alphaorder~',
                  id: Year,
                  code: courseCode,
                  title: 'Tournament Entries',
                },
              }"
            >
              Full Listing
            </b-nav-item>
            <b-nav-item
              v-if="title === 'Live'"
              class="right four Flisting-btn"
              :to="{
                name: 'reports',
                query: {
                  url:
                    config.VUE_APP_PLAYER_HOST +
                    '/tic/tmentry.cgi?tourn=' +
                    courseCode +
                    '~season=' +
                    Year +
                    '~alphaorder~',
                  id: Year,
                  code: courseCode,
                  title: 'Tournament Entries',
                },
              }"
            >
              Full Listing
            </b-nav-item>
          </b-col> -->
        </b-row>
      </b-container>
    </div>
  </b-container>
</template>

<script>
import apiCall from "@/services/api.js";
import ReportTable from "@/components/reportTable.vue";

export default {
  name: "homeTourOne",
  components: {
    ReportTable,
  },
  props: [
    "Year",
    "courseCode", 
    "currentReport", 
    "reportURL", 
    "config",
    "currentTitle",
    "scoreFound",
    "showGender",
    "is_qualifier",
    "stableford",
    "match"
  ],

  data() {
    return {
      updown: false,
      fields: [
        { isActive: true, key: "serial_no", label: "No." },
        { isActive: true, key: "playing_name", label: "Player Name" },
        { isActive: true, key: "", label: "Nat." },
        { isActive: true, key: "status_text", label: "Entry Status" },
        { isActive: true, key: "player_profile", label: "" },
      ],
      tableRow: "tableRow",
      data: [],
      tab: "tab",
      cell: "cell",
      home: true,
    };
  },
  computed: {
    liveOn: function(message) {
      if (this.data.live_scoring === "Y") message = " - Livescoring is on";
      else message = " - " + this.data.round_head;
      return message;
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    title: function(title) {
      if (this.currentReport.includes("entry")) title = "Tournament Entries";
      else if (this.currentReport.includes("draw")) title = "Draw";
      else if (this.currentReport.includes("latest")) title = "Live";
      else if (this.currentReport.includes("score")) title = "Scores";
      else if (this.currentReport.includes("result")) title = "Final Result";

      return title;
    },
  },
  methods: {
    updateStuff: function() {
      apiCall
        .report(this.currentReport)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    upDown() {
      this.updown = !this.updown;
    },
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  mounted() {
    apiCall
      .report(this.currentReport)
      .then(({ data }) => {
        this.data = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
::v-deep th {
  padding-top: 15px;
  padding-bottom: 15px;
}
.quickScore {
  padding: 0 0 50px 0;
}
li {
  list-style: none;
}
.links {
  text-align: center;
  padding: 10px;
}
.red {
  background-color: #183167;
}
.red:hover {
  background-color: #668CE0;
}
.blue {
  background-color: #3E3E3E;
}
.links > li > a {
  color: #fff !important;
}
</style>
