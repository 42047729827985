<template>
  <div class="menu">
    <template>
    <div class="subpageHeader test"
    >
      <div id="nav" class="boxShadow">
        <b-navbar type="dark" toggleable="lg" id="topNav">
          <b-navbar-brand to="/">
            <img
              class="logoImg"
              :class="{
                margB:
                  this.$route.name !== 'home',
              }"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/bvt/2024/06/Lable-Tour-Logo.png"
            />
          </b-navbar-brand>
          <b-navbar-toggle
            class="ml-auto"
            type="dark"
            v-b-modal.nav-collapse
          ></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/">
                HOME
              </b-nav-item>
              <b-nav-item to="/schedule">
                SCHEDULE
              </b-nav-item>
              <template v-if="this.okfound == 'Y' && this.course !== null">
                <b-nav-item
                  :to="{
                    name: 'reports',
                    query: {
                      url: currentReport(),
                      id: season,
                      code: course,
                      fTour: true,
                      title: currentTitle(),
                    },
                  }"
                >
                  RESULTS
                </b-nav-item>
              </template>
              <b-nav-item to="/oom">
                OOM
              </b-nav-item>
              <b-nav-item to="/tournaments-information">
                TOUR INFO
              </b-nav-item>
              <b-nav-item to="/tour-incentives">
                TOUR INCENTIVES
              </b-nav-item>
              <b-nav-item to="/membership">
                MEMBERSHIP
              </b-nav-item>
              <b-nav-item to="/news" class="rightPix">
                NEWS
              </b-nav-item>
              <!-- <b-nav-item to="/contact-us">
                CONTACT US
              </b-nav-item> -->
              <!-- <b-nav-item
                :href="'https://twitter.com/' + config.VUE_APP_TWITTER_NAME"
              >
                <font-awesome-icon
                  class="icon"
                  :icon="['fab', 'twitter-square']"
                />
              </b-nav-item>
              <b-nav-item
                :href="'https://www.instagram.com/' + config.VUE_APP_INSTA_NAME"
              >
                <font-awesome-icon
                  class="icon"
                  :icon="['fab', 'instagram-square']"
                />
              </b-nav-item> -->
            </b-navbar-nav>
          </b-collapse>
          <b-modal hide-header hide-footer id="nav-collapse">
            <b-button class='closeBtn' @click="$bvModal.hide('nav-collapse')">
              <img src="https://assets.asiantour.com/toyota-tour/2024/02/close_360.png" class="closeIcon">
            </b-button>
            <div class="mobileMenu">
              <div class="menuGroup">
                <b-nav-item to="/" @click="$bvModal.hide('nav-collapse')">
                  HOME
                </b-nav-item>
                <b-nav-item
                  to="/schedule"
                  @click="$bvModal.hide('nav-collapse')"
                >
                  SCHEDULE
                </b-nav-item>
                <template v-if="this.okfound == 'Y' && this.course !== null">
                  <b-nav-item
                    @click="$bvModal.hide('nav-collapse')"
                    :to="{
                      name: 'reports',
                      query: {
                        url: currentReport(),
                        id: season,
                        code: course,
                        fTour: true,
                        title: currentTitle(),
                      },
                    }"
                  >
                    RESULTS
                  </b-nav-item>
                </template>
                <b-nav-item to="/oom" @click="$bvModal.hide('nav-collapse')">
                  OOM
                </b-nav-item>
                <b-nav-item to="/tournaments-information" @click="$bvModal.hide('nav-collapse')">
                  TOUR INFO
                </b-nav-item>
                <b-nav-item to="/tour-incentives" @click="$bvModal.hide('nav-collapse')">
                  TOUR INCENTIVES
                </b-nav-item>
                <b-nav-item to="/membership" @click="$bvModal.hide('nav-collapse')">
                  MEMBERSHIP
                </b-nav-item>
                <b-nav-item to="/news" @click="$bvModal.hide('nav-collapse')">
                  NEWS
                </b-nav-item>
                <!-- <b-nav-item
                  to="/contact-us"
                  @click="$bvModal.hide('nav-collapse')"
                  class="cont"
                >
                  CONTACT US
                </b-nav-item> -->
                <!-- <b-nav-item
                  :href="'https://twitter.com/' + config.VUE_APP_TWITTER_NAME"
                >
                  <font-awesome-icon
                    id="twit"
                    class="icon"
                    :icon="['fab', 'twitter-square']"
                  />
                </b-nav-item>
                <b-nav-item
                  :href="
                    'https://www.instagram.com/' + config.VUE_APP_INSTA_NAME
                  "
                >
                  <font-awesome-icon
                    id="insta"
                    class="icon fa-gradient"
                    :icon="['fab', 'instagram-square']"
                  />
                </b-nav-item> -->
              </div>
            </div>
          </b-modal>
        </b-navbar>
      </div>
      <div class="redbanner"></div>
    </div>
    <div class="videoTextBlock">
      <template v-if="$route.name == 'home'">
        <template v-if="isMobileimage(true)">
          <div class="mobileImage">

          </div>
        </template>
        <template v-else>
          <img class="MainImage" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/bvt/2024/06/Blue_Giraffes.png">
        </template>
          <template v-if="this.$route.name === 'home'">
          <div class="bannerTitle container">
            <h1 class="BannerText">
              {{homeSectionData.first_line_text}}
              <br />
              {{homeSectionData.second_line_text}}
              <br />
              {{homeSectionData.slogan}}
              <br />
            </h1>
          </div>
        </template>
      </template>
    </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import apiCall from "@/services/api.js";
export default {
  name: "Menu",
  props: ["route", "config"],
  data() {
    return {
      menueLink: "menuLink",
      course: [],
      season: [],
      showModal: false,
      tmparams: [],
      reports: [],
      homeSectionData:[]
    };
  },
  computed: {
    banner: function(ban) {
      if (this.$route.name === "home") ban = "2021/03/Tartan_Main_Home_2.jpg";
      else ban = "2021/03/headerbar.png";
      return ban;
    },

    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          this.config.VUE_APP_APIFULL_URL +
          this.tm_params.tm_params.season_code +
          "/" +
          this.tm_params.tm_params.season_code +
          "-" +
          this.course +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscores") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_APIFULL_URL 
              +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/" + "dev" + "/cache/" + "dev" + "/" + "215S" + "/" + "215S" + "-" + "AUS7" + "-scores-" + $scope.repParams + ".json"+"?randomadd=1603894106415";
          } else {
            url =
              this.config.VUE_APP_APIFULL_URL 
              +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              this.config.VUE_APP_APIFULL_URL 
              +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_APIFULL_URL 
            +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            this.config.VUE_APP_APIFULL_URL 
            +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_APIFULL_URL 
            +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
  },
  created() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      sessionStorage.setItem("showModal", "yes");
    } else {
      sessionStorage.setItem("showModal", "no");
    }
  },
  methods: {
    
    show() {
      this.showModal = true;
      this.$emit("show-me");
    },
    close() {
      this.showModal = false;
      sessionStorage.setItem("showModal", "no");
      this.$emit("hide-me");
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isMobileimage() {
      if( screen.width <= 500 ) {
        return true;
      }
      else {
        return false;
      }
    },
    currentReport() {
      if (Array.isArray(this.reports))
        if (this.tmparams.rounds_played == '0') {
            return this.reports[this.reports.length - 1].report_url;
        } else {
            return this.reports[this.reports.length - 4].report_url;
        }
        else return this.reports.report_url;
    },
    currentTitle() {
      if (Array.isArray(this.reports))
      if (this.tmparams.rounds_played == '0') {
        return this.reports[this.reports.length - 1].report_title;
      } else {
        return this.reports[this.reports.length - 4].report_title;
      }
      else return this.reports.report_title;
    },
    HomeSection: function() {
        axios.get(
        "https://wp-bvt.ocs-software.com/index.php/wp-json/wp/v2/HomeSection?randomadd=" +
        new Date().getTime()
        )
        .then(response => {
          this.homeSectionData = response.data[0].acf;
          console.log("this.homeSectionData")
          console.log(this.homeSectionData)
        })  
    }
  },
  mounted() {
    this.HomeSection();
    const showModal = sessionStorage.getItem("showModal");
    console.log(showModal);
    if (showModal === "yes") setTimeout(() => this.$refs.myModal.show(), 3500);
        apiCall 
      .tmParamsBase()
      .then(({ data }) => {
        this.course = data.code;
        this.okfound = data.ok_found;
        this.tmparams = data;
        this.reports = data.reports.reports_entry;
        this.season = data.tm_params.season_code;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
::v-deep .boxShadow {
  /* box-shadow: 0px 3px 10px #00000034; */
  background-color: #253564;
}
.subpageHeader.test {
  background-color: #253564;
  margin-bottom: -3px;
}
.MainImage {
  width: 100%;
}
.closeIcon {
  width: 20px;
}
.mobileImage {
  width: 100%;
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/bvt/2024/06/Blue_Giraffes.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}
.videoTextBlock {
  
}
video#myVideo {
  width: 100%;
}
::v-deep .navbar-light .navbar-toggler-icon {
 background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
::v-deep .navbar-light .navbar-toggler {
  color: rgba(255, 255, 255)!important;
  border-color: rgba(255, 255, 255)!important;
}
::v-deep .navbar-dark .navbar-toggler-icon {
 background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
/* ::v-deep .navbar-dark .navbar-toggler {
  color: rgb(0, 0, 0)!important;
  border-color: rgb(0, 0, 0)!important;
} */
/* ::v-deep .navbar-dark .navbar-toggler-icon {
  background-image: url('https://assets.asiantour.com/toyota-tour/2024/02/kisspng-computer-icons-hamburger-button-menu-new-menu-5b34724be5a1f0.5796308115301637879406.jpg');
} */
::v-deep .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0);
}
.rightPix {
  margin-right: 10px;
}
.navbar-light {
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbar-dark {
  padding-top: 20px;
  padding-bottom: 20px;
}
.redbanner {
  height: 4px;
  /* background-color: #183167; */
  width: 100%;
}
h1.BannerText {
  font-weight: 700;
  /* font-size: 4.5rem; */
  font-size: 2.7rem;
}
.router-link-exact-active {
  color: #668CE0 !important;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:active {
  color: #668CE0;
  padding: 0 19px 0 19px;
  font-size: 0.95rem;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:active {
  color: #668CE0;
  padding: 0 19px 0 19px;
  font-size: 0.95rem;
}
::v-deep .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mobile_Navdrawer_BG.png) !important;
}
::v-deep div#modal-1___BV_modal_content_ {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mobile_Navdrawer_BG.png) !important;
}
::v-deep .modal-content {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mobile_Navdrawer_BG.png) !important;
}
h2.dLoad {
  font-size: 23pt;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
}
li {
  list-style: none;
}
.dLoadImg {
  width: 225px;
}
.downloadLinks {
  text-align: center;
}
img.appStore {
  width: 200px;
  text-align: center;
}
.Downloadbox {
  text-align: center;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  padding: 0 19px 0 19px;
  font-size: 0.95rem;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
  padding: 0 19px 0 19px;
  font-size: 0.95rem;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #668CE0;
  padding: 0 19px 0 19px;
  font-size: 0.95rem;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #668CE0;
  padding: 0 19px 0 19px;
  font-size: 0.95rem;
}
.bannerImg {
  background-repeat: no-repeat;
  background-position-y: top;
  background-size: cover;
  display: block;
  position: relative;
  width: 100%;
  height: calc(35vh + 35vw);
  background-position-x: right;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 10%);
  background-attachment: inherit;
  margin-bottom: 0;
}
.bannerTitle {
  padding: 0 0 10%;
  color: white;
  margin-top: -13em;
  margin-bottom: -3em;
}
.homeGolf {
  padding: 2% 0;
  color: #183167;
  font-style: italic;
}
.bannerTitle > button {
  background-color: transparent;
  border-color: white;
  font-size: 1.5rem;
  border-radius: 0;
  padding: 1% 7%;
}
.bannerTitle > a.btn {
  background-color: transparent;
  border-color: white;
  font-size: 1.5rem;
  border-radius: 0;
  padding: 1% 7%;
}
.bannerTitle > a.btn:hover {
  background-color: #668CE0;
  border-color: white;
  font-size: 1.5rem;
  border-radius: 0;
  padding: 1% 7%;
}
.logoImg {
  width: 130px;
  margin-top: 5px;
  margin-bottom: -25px;
  margin-left: 10%;
  margin-bottom: 5px !important;
}
::v-deep .svg-inline--fa {
  font-size: 1.5rem;
}
@media only screen and (max-width: 1090px) {
  h1.BannerText {
    font-weight: 700;
    font-size: 2rem;
  }
  .bannerTitle {
    padding: 0 0 10%;
    color: white;
    margin-top: -9em;
    margin-bottom: -3.5em;
  }
}
@media only screen and (max-width: 768px) {

  .bannerTitle {
    padding: 0 0 10%;
    color: white;
    margin-top: -7em;
    margin-bottom: -3.5em;
  }
  .cont > a {
    padding-left: 0;
    padding-right: 0;
  }
  .bannerTitle > h1 {
    font-size: 1.5rem;
  }
  .mobileMenu {
    /* background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mobile_Navdrawer_BG.png); */
    border: 0px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    padding: 0;
    top: -30px;
    left: -149px;
    height: 109vh;
    background-position-y: bottom;
    background-color: #668CE0;
  }
  .menuGroup {
    padding: 10vh 61vw;
    margin-left: -8px;
  }
  .nav-link {
    color: #fff;
    font-size: 20pt;
    line-height: 2rem;
    font-weight: 500;
    text-align: center;
  }
  ::v-deep #modal-1___BV_modal_content_ {
    background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mobile_Navdrawer_BG.png);
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    text-align: center;
    border-radius: 10px;
  }
  .appStore {
    width: 100%;
  }
  .dLoad {
    font-size: 1.5rem;
    text-align: center;
    padding: 50px 0;
  }
  .nav-item {
    margin: 20px;
  }
  .closeBtn{
    color: #000;
    z-index: 9999;
    position: absolute;
    background: transparent;
    border: 0;
    right: 0;
    font-weight: 900;
  }
}
@media only screen and (max-width: 500px) {
  .logoImg {
    width: 60px;
  }
  .bannerTitle > h1 {
    font-size: 1.2rem;
  }
  .bannerTitle {
    padding: 0 0 10%;
    color: white;
    margin-top: -8em !important;
    margin-bottom: -42px;
    padding-left: 22px;
    padding-right: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .bannerTitle {
    padding: 0 0 10%;
    color: white;
    margin-top: -8em !important;
    margin-bottom: -42px;
    padding-left: 22px;
    padding-right: 10px;
  }
}
@media only screen and (max-width: 375px) {
  .bannerTitle {
    padding: 0 0 10%;
    color: white;
    margin-top: -15em!important;
    margin-bottom: 45px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .bannerTitle > h1 {
    font-size: 1.5rem;
  }
}
</style>
