import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    props:true 
  },
  {
    path: "/home",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home.vue")
  },
  {
    path: "/schedule",
    name: "schedule",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule.vue")
  },
  {
    path: "/tournaments-information",
    name: "tournaments-information",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/membership",
    name: "membership",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/tour-incentives",
    name: "/tour-incentives",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/results/",
    name: "reports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/reports.vue"),
    props: true
  },
  {
    path: "/oom/",
    name: "oom",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/oom.vue"),
    props: true
  },
  {
    path: "/news",
    name: "News",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/news.vue")
  },
  {
    path: "/contact-us",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contact.vue")
  },
  {
    path: "/privacy",
    name: "privacy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/privacy.vue")
  },
  {
    path: "/tournament/:course_code/:id",
    name: "tournament",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tournament.vue"),
    props: true
  },
  {
    path: "/:id",
    name: "news-story",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  { 
    path: '/:catchAll(.*)', 
    name: 'NotFound'
  }
];

const router = new VueRouter({
  routes,
  mode:'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});

export default router;
