import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueMeta from "vue-meta";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import JQuery from "jquery";
import VueInstagram from "vue-instagram";
import VueGtag from "vue-gtag";

library.add(
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faYoutubeSquare,
  faArrowDown,
  faArrowUp,
  faPlayCircle
);
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueInstagram);
Vue.use(VueGtag, {
  config: { id: "G-ZHN84FMRZT" },
  includes: [
    { id: 'G-ZHN84FMRZT' }
  ]
}, router);


new Vue({
  router,
  store,
  axios,
  JQuery,
  lodash,
  render: h => h(App)
}).$mount("#app");
