var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"quickScore"},[_c('div',[_c('ReportTable',{attrs:{"data":_vm.data,"title":_vm.title,"home":_vm.home,"updown":_vm.updown,"config":_vm.config,"team":_vm.teamEvent,"match":_vm.match,"currentTitle":_vm.currentTitle,"showGender":_vm.showGender,"is_qualifier":_vm.is_qualifier,"stableford":_vm.stableford}}),_c('b-container',{staticStyle:{"display":"contents"}},[_c('b-row',{staticStyle:{"margin-left":"0","margin-right":"0"}},[_c('b-col',{staticClass:"links red"},[(_vm.title === 'Tournament Entries')?_c('b-nav-item',{staticClass:"Flisting-btn right",attrs:{"to":{
              name: 'reports',
              query: {
                url:
                  _vm.config.VUE_APP_PLAYER_HOST +
                  '/tic/tmentry.cgi?tourn=' +
                  _vm.courseCode +
                  '~season=' +
                  _vm.Year +
                  '~alphaorder~',
                id: _vm.Year,
                code: _vm.courseCode,
                title: _vm.currentTitle,
              },
            }}},[_vm._v(" Full Listing ")]):_vm._e(),(_vm.title === 'Draw')?_c('b-nav-item',{staticClass:"left drawListing-btn",attrs:{"to":{
              name: 'reports',
              query: {
                url: _vm.reportURL,
                id: _vm.Year,
                code: _vm.courseCode,
                title: _vm.currentTitle,
              },
            }}},[_vm._v(" Full Listing ")]):_vm._e(),(_vm.title === 'Scores')?_c('b-nav-item',{staticClass:"center three Flisting-btnt",attrs:{"to":{
              name: 'reports',
              query: {
                url: _vm.reportURL,
                id: _vm.Year,
                code: _vm.courseCode,
                title: _vm.currentTitle,
              },
            }}},[_vm._v(" Leaderboard ")]):_vm._e(),(_vm.title === 'Live')?_c('b-nav-item',{staticClass:"center four Flisting-btn",attrs:{"to":{
              name: 'reports',
              query: {
                url: _vm.reportURL,
                id: _vm.Year,
                code: _vm.courseCode,
                title: _vm.currentTitle,
              },
            }}},[_vm._v(" Leaderboard ")]):_vm._e(),(_vm.title === 'Final Result')?_c('b-nav-item',{staticClass:"center four Flisting-btn",attrs:{"to":{
              name: 'reports',
              query: {
                url: _vm.reportURL,
                id: _vm.Year,
                code: _vm.courseCode,
                title: _vm.currentTitle,
              },
            }}},[_vm._v(" Full Listing ")]):_vm._e()],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }