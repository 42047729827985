<template>
  <div>
    <b-row class="squareGrid">
      <b-col lg="6" md="12" clos="12" class="squareColLeft">
      </b-col>
      <b-col id="blueGrad" lg="6" md="12" cols="12" class="squareCol">
        <div class="container">
          <div class="gradText">
            <h3 class="gridTitleRight" >
              {{ data.blue_title }}
            </h3>
            <br />
            <p>
              <span v-html="data.blue_text"></span>
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="squareGrid">
      <b-col
        lg="6"
        md="12"
        cols="12"
        order="2"
        order-lg="2"
        order-md="1"
        class="squareColRight"
      ></b-col>
      <b-col
        id="redGrad"
        lg="6"
        md="12"
        cols="12"
        order="1"
        order-lg="1"
        order-md="2"
      >
        <div class="container">
          <div class="gradTextRed">
            <h3 class="gridTitleLeft" style="color:white;">{{ data.red_title }}</h3>
            <br />
            <span v-html="data.red_text"></span>
            <br />
            <div id="appsStore">
              <a :href="config.VUE_APP_APPLE_STORE" target="_blank">
                <img
                  class="app"
                  src="https://assets.asiantour.com/toyota-tour/2024/02/Mask-Group-5.png"
                />
              </a>
              <a :href="config.VUE_APP_GOOGLE_PLAY"  target="_blank">
                <img
                  class="app"
                  src="https://assets.asiantour.com/toyota-tour/2024/02/Mask-Group-4.png"
                />
              </a>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "cubes",
  props: ["config"],
  data() {
    return {
      data: [],
    };
  },
  created() {
    const json = "wp/v2/pages/8";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.data = data.acf;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
iframe {
  border: none;
}
img.app {
  padding-right: 15px;
  width: 200px !important;
}
#homeButton:hover {
  background-color: #183167;
  color: white;
  border-color: #183167;
  border-style: solid;
  border-width: thin;
  padding: 10px 50px 10px 50px;
}
.squareColLeft {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/bvt/2024/03/428469645_18012897176327084_6902805058850734578_n-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-right: 0px;
}
#blueGrad {
  background-color: #183167;
  color: white;
}
#redGrad {
  background-color: #668CE0;
  color: white;
}
.squareColRight {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/bvt/2024/03/423250017_18010805945327084_320809635507007439_n.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.gridTitle {
  font-size: calc(1.2vw + 1.7vh);
}
.gridTitleRight {
  font-size: 32px;
}
.gradText > p > span > p {

}
.gridTitleLeft {
  font-size: 42px;
}
.GridSmall {
  font-weight: 100;
}
.gradTextRed {
  padding-top: calc(5vw + 3vh);
  padding-left: calc(3vw + 3vh);
  padding-right: calc(3vw + 3vh);
  padding-bottom: calc(5vw + 3vh);
}
.gradText {
  padding-top: calc(3vw + 3vh);
  padding-left: calc(3vw + 3vh);
  padding-right: calc(3vw + 3vh);
  padding-bottom: calc(3vw + 3vh);
}
#homeButton {
  background-color: transparent;
  color: white;
  border-color: white;
  border-style: solid;
  border-width: thin;
  padding: 10px 50px 10px 50px;
}
@media only screen and (max-width: 990px) {
  .squareGrid {
    display: block;
  }
  .squareColLeft {
    height: 800px;
  }
  .squareColRight {
    height: 800px;
  }
}
@media only screen and (max-width: 480px) {
  .squareColLeft {
    height: 500px;
  }
  .squareColRight {
    height: 500px;
  }
}
</style>
